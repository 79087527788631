import CloseIcon from "@mui/icons-material/CloseRounded";
import CropFreeIcon from "@mui/icons-material/CropFreeRounded";
import { Box, IconButton, Stack, ToggleButtonGroup } from "@mui/material";
import { MeasureCalculationGranularity } from "api-shared";
import { TFunction } from "i18next";
import { Moment } from "moment";
import React from "react";
import ToggleButton from "../../../components/ToggleButton";
import Tooltip from "../../../components/Tooltip";
import CalculationTimerangeSelect from "../../../components/input/date/CalculationTimerangeSelect";
import { translationKeys } from "../../../translations/main-translations";
import LevelSelector from "./LevelSelector";

interface IExtendedCalculationHeaderProps {
    granularity: MeasureCalculationGranularity;
    updateGranularity: (newGranularity: MeasureCalculationGranularity) => void;
    translate: TFunction;
    isFullscreen: boolean;
    toggleFullscreen: () => void;
    fiscalYearStart: number;
    // date range selector props
    start: Moment | null;
    onStartChanged: (newValue: Moment) => void;
    end: Moment | null;
    onEndChanged: (newValue: Moment) => void;
    maxStart?: Moment;
    minEnd?: Moment;
    disabled: boolean;
    visibleCalculationIdentifiers: string[];
    calculationIdentifiers: string[];
    updateVisibleCalculationIdentifiers: (newLevels: string[]) => void;
}

const GranularityOrder = [
    MeasureCalculationGranularity.MONTH,
    MeasureCalculationGranularity.FISCAL_QUARTER,
    MeasureCalculationGranularity.FISCAL_YEAR,
];

const ExtendedCalculationHeader = ({
    granularity,
    updateGranularity,
    translate,
    isFullscreen,
    toggleFullscreen,
    fiscalYearStart,
    start,
    end,
    onStartChanged,
    onEndChanged,
    maxStart,
    minEnd,
    disabled,
    visibleCalculationIdentifiers,
    calculationIdentifiers,
    updateVisibleCalculationIdentifiers,
}: IExtendedCalculationHeaderProps) => {
    const handleGranularityChange = (event: React.MouseEvent<HTMLElement>, value: MeasureCalculationGranularity) => {
        // value is null, if user clicked on already selected button
        if (value != null) {
            updateGranularity(value);
        }
    };

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <LevelSelector
                calculationIdentifiers={calculationIdentifiers}
                visibleCalculationIdentifiers={visibleCalculationIdentifiers}
                updateVisibleCalculationIdentifiers={updateVisibleCalculationIdentifiers}
                translate={translate}
            />
            <Box flexGrow={1} />
            <CalculationTimerangeSelect
                start={start}
                onStartChanged={onStartChanged}
                end={end}
                onEndChanged={onEndChanged}
                fiscalYearStart={fiscalYearStart}
                granularity={granularity}
                translate={translate}
                maxStart={maxStart}
                minEnd={minEnd}
                disabled={disabled}
                dense
            />
            <ToggleButtonGroup value={granularity} exclusive onChange={handleGranularityChange}>
                {GranularityOrder.map((value) => (
                    <ToggleButton
                        key={value}
                        value={value}
                        size="small"
                        disabled={disabled}
                        title={translate(`${translationKeys.VDLANG_MEASURE_CALCULATION_GRANULARITY_TOOLTIP}.${value}`)}
                    >
                        {translate(`${translationKeys.VDLANG_MEASURE_CALCULATION_GRANULARITY}.${value}`)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {isFullscreen ? (
                <Tooltip title={translate(translationKeys.VDLANG_PROCESS_TOOLTIPS_CLOSE_FULLSCREEN)}>
                    <IconButton sx={{ p: 1 }} onClick={toggleFullscreen}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title={translate(translationKeys.VDLANG_PROCESS_TOOLTIPS_FULLSCREEN)}>
                    <IconButton sx={{ p: 1 }} onClick={toggleFullscreen}>
                        <CropFreeIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
};

export default React.memo(ExtendedCalculationHeader);
