import type { IDataGridOptions } from "devextreme-react/data-grid";
import { synchronizeCssClass } from "../../../styles/utils";

export const classLastOfFiscalYear = "lastOfFiscalYear";

export const addFiscalYearHightlightClass: NonNullable<IDataGridOptions["onRowPrepared"]> = ({ rowIndex, data, component, rowElement }) => {
    if (data === undefined || rowIndex === undefined || component === undefined || rowElement === undefined) {
        return;
    }

    const row = data;
    const rows = component.getVisibleRows();

    const nextRowData = rows[rowIndex + 1]?.data;
    const isLastRowOfFiscalYear =
        nextRowData != null && nextRowData.fiscalMoment.fiscalMoment.year() !== row.fiscalMoment.fiscalMoment.year();
    synchronizeCssClass(rowElement, classLastOfFiscalYear, isLastRowOfFiscalYear);
};
