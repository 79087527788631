import {
    CalculationEffectListDto,
    EffectCategoryDto,
    EffectFilterCurrencyField,
    EffectType,
    EffectValueType,
    FiscalMoment,
    GlobalCalculationIdentifier,
    MeasureCalculationGranularity,
    buildEffectKey,
    generateFiscalMomentsFromInterval,
    nonNullable,
} from "api-shared";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { isEqual, keyBy } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useMemo } from "react";
import { calendarToFiscal } from "../../../lib/fiscal-units";
import { EffectColumnIdentifier } from "./useCalculationTableColumns";

interface EffectValues {
    value: number;
    inputValue: number;
}

export interface IEffectRow extends Record<string, string | FiscalMoment | EffectValues | null> {
    id: string;
    fiscalMoment: FiscalMoment;
}

function makeTableRows(
    calculationEffects: CalculationEffectListDto,
    granularity: MeasureCalculationGranularity,
    fiscalYearStart: number,
    start: Moment | null,
    end: Moment | null,
    effectCategories: EffectCategoryDto[],
    visibleCalculationIdentifiers: GlobalCalculationIdentifier[],
): IEffectRow[] {
    const fiscalMoments = generateFiscalMomentsFromInterval(start, end, granularity, fiscalYearStart);
    const rows: IEffectRow[] = fiscalMoments.map((fiscalMoment) => ({
        fiscalMoment,
        id: generateRowId(fiscalMoment, granularity),
    }));
    const ecById = keyBy(effectCategories, "id");

    const effectRowsByFiscalUnit = calculationEffects.reduce(
        (rowsById, { effectCategoryId, month, year, ...effectFields }) => {
            const ec = ecById[effectCategoryId];

            if (ec == null) {
                return rowsById;
            }

            visibleCalculationIdentifiers.forEach((calculationIdentifier) => {
                // column per field (target, effect, priceHike)
                [EffectFilterCurrencyField.Effect, EffectFilterCurrencyField.Initial, EffectFilterCurrencyField.PriceHike].forEach(
                    (field) => {
                        const effectValue = effectFields[buildEffectKey(calculationIdentifier, field, EffectValueType.Calculated)];
                        const effectInputValue = effectFields[buildEffectKey(calculationIdentifier, field, EffectValueType.Input)];

                        if (effectValue === null && effectInputValue === null) {
                            return;
                        }

                        const columnId = new EffectColumnIdentifier(calculationIdentifier, effectCategoryId, field).toString();

                        const effect = { month, year, value: effectValue ?? 0, inputValue: effectInputValue ?? 0 };

                        const calendarMoment = moment({ month, year, day: 1 });
                        const fiscalMoment = calendarToFiscal(calendarMoment, fiscalYearStart, granularity);
                        const rowId = generateRowId(fiscalMoment, granularity);

                        // add up effects in case case of non-monthly granularity
                        rowsById[rowId][columnId] = {
                            inputValue: ((rowsById[rowId][columnId] as EffectValues)?.inputValue ?? 0) + effect.inputValue,
                            value: ((rowsById[rowId][columnId] as EffectValues)?.value ?? 0) + effect.value,
                        };
                    },
                );
            });

            return rowsById;
        },
        keyBy(rows, "id"),
    );

    const effectCategoriesById = keyBy(effectCategories, "id");

    return Object.values(effectRowsByFiscalUnit).map(({ id, fiscalMoment, ...effects }) => {
        const inputEffects = Object.keys(effects).reduce(
            (acc, cur) => {
                acc[cur] = (effects[cur] as EffectValues).inputValue;
                return acc;
            },
            {} as Record<string, number>,
        );

        return {
            id,
            fiscalMoment,
            ...inputEffects,
            ...generateSumRowData(effects as Record<string, EffectValues>, effectCategoriesById),
        };
    });
}

function generateSumRowData(
    effects: Record<string, EffectValues>,
    effectCategoriesById: Record<number, EffectCategoryDto>,
): Record<string, number> {
    return Object.entries(effects).reduce(
        (sumColumns, [columnName, effectValues]) => {
            const { effectCategoryId, calculationIdentifier, type } = EffectColumnIdentifier.fromString(columnName);

            // only sum potential values
            if (type !== EffectFilterCurrencyField.Effect) {
                return sumColumns;
            }

            const sumColumnName = `total_${calculationIdentifier}_${EffectFilterCurrencyField.Effect}`;

            if (sumColumns[sumColumnName] == null) {
                sumColumns[sumColumnName] = 0;
            }

            const effectType = effectCategoriesById[effectCategoryId]?.effectType;

            sumColumns[sumColumnName] += effectValues.value * (effectType === EffectType.ChangeoverCosts ? -1 : 1);

            return sumColumns;
        },
        {} as Record<string, number>,
    );
}

function generateRowId({ calendarMoment, fiscalMoment }: FiscalMoment, granularity: MeasureCalculationGranularity) {
    switch (granularity) {
        case MeasureCalculationGranularity.MONTH:
            return calendarMoment.format("[M]MM-YYYY");
        case MeasureCalculationGranularity.FISCAL_QUARTER:
            return fiscalMoment.format("[Q]Q-YYYY");
        case MeasureCalculationGranularity.FISCAL_YEAR:
        default:
            return fiscalMoment.format("YYYY");
    }
}

interface IBasePushEvent<T extends string> {
    key: string;
    type: T;
}

type IRemoveEvent = IBasePushEvent<"remove">;

interface IInsertEvent extends IBasePushEvent<"insert"> {
    data: IEffectRow;
    index?: number;
}

interface IUpdateEvent extends IBasePushEvent<"update"> {
    data: Partial<IEffectRow>;
}
type PushEvent = IRemoveEvent | IInsertEvent | IUpdateEvent;

function generatePushEvents(
    removedKeys: string[],
    addedKeys: string[],
    retainedKeys: string[],
    previousTableData: IEffectRow[],
    newTableData: IEffectRow[],
    keyProp: string,
): PushEvent[] {
    const removeEvents = removedKeys.map((id) => ({ type: "remove" as const, key: id }));

    const insertEvents = addedKeys
        .map((key) => {
            const data = newTableData.find((row) => row[keyProp] === key);
            return data !== undefined
                ? {
                      type: "insert" as const,
                      key,
                      data,
                  }
                : undefined;
        })
        .filter(nonNullable);

    // deeply compare rows to skip sending events for unchanged rows to store
    const updateEvents = retainedKeys.reduce((acc, key) => {
        const oldRow = previousTableData.find((x) => x[keyProp] === key);
        const newRow = newTableData.find((x) => x[keyProp] === key);
        if (oldRow !== undefined && newRow !== undefined && !isEqual(oldRow, newRow)) {
            // set removed keys explicitly to null
            const newColumns = Object.keys(newRow);
            const deletedColumns = Object.keys(oldRow).filter((k) => !newColumns.includes(k));
            acc.push({
                type: "update",
                key,
                data: deletedColumns.reduce(
                    (acc, key) => {
                        acc[key] = null;
                        return acc;
                    },
                    { ...newRow },
                ),
            });
        }
        return acc;
    }, [] as IUpdateEvent[]);

    // order is not important here, DataGrid does sort the data anyway
    return [...insertEvents, ...removeEvents, ...updateEvents];
}

interface IUseCalculationTableDataProps {
    granularity: MeasureCalculationGranularity;
    fiscalYearStart: number;
    start: Moment | null;
    end: Moment | null;
    effectCategories: EffectCategoryDto[];
    calculationEffects: CalculationEffectListDto;
    visibleCalculationIdentifiers: GlobalCalculationIdentifier[];
}

const useCalculationTableData = ({
    calculationEffects,
    granularity,
    fiscalYearStart,
    start,
    end,
    effectCategories,
    visibleCalculationIdentifiers,
}: IUseCalculationTableDataProps): DataSource<IEffectRow, string> => {
    // Create an (initially empty) DataSource, whose reference will keep stable until component unmount
    // This way, the table will avoid costly re-initialization when a new DataSource is provided
    // Drawback: The DataSource has to be synchronized manually with updated effects coming in
    const dataSource = useMemo(() => {
        const store = new ArrayStore<IEffectRow, string>({
            data: makeTableRows(
                calculationEffects,
                granularity,
                fiscalYearStart,
                start,
                end,
                effectCategories,
                visibleCalculationIdentifiers,
            ),
            key: "id",
        });
        return new DataSource<IEffectRow, string>({
            store,
            reshapeOnPush: true, // when changes are pushed, notify DataGrid to recompute its internal data structures (e.g. sorting, sums)
        });
        // dependencies are ignored, because effect below keeps the data up-to-date
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Make sure DataSource is disposed on component unmount
    useEffect(() => () => dataSource.dispose(), [dataSource]);

    // When data dependencies change, compute the actual differences and push those to the store instance
    useEffect(() => {
        const newTableData = makeTableRows(
            calculationEffects,
            granularity,
            fiscalYearStart,
            start,
            end,
            effectCategories,
            visibleCalculationIdentifiers,
        );

        const store = dataSource.store();
        const previousTableData = dataSource.items() as IEffectRow[];
        const keyProp = dataSource.key() as string;

        const oldKeys = previousTableData.map((row) => row[keyProp] as string);
        const newKeys = newTableData.map((row) => row[keyProp] as string);

        const removedKeys = oldKeys.filter((id) => !newKeys.includes(id));
        const addedKeys = newKeys.filter((id) => !oldKeys.includes(id));
        const retainedKeys = [...newKeys].filter((key) => oldKeys.includes(key));

        const events = generatePushEvents(removedKeys, addedKeys, retainedKeys, previousTableData, newTableData, keyProp);
        store.push(events);
    }, [dataSource, calculationEffects, granularity, fiscalYearStart, start, end, effectCategories, visibleCalculationIdentifiers]);

    return dataSource;
};

export default useCalculationTableData;
