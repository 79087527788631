import { ToggleButton as MuiToggleButton, alpha, styled, toggleButtonClasses } from "@mui/material";

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
    [`&.${toggleButtonClasses.sizeSmall}`]: {
        padding: theme.spacing(0.5, 1.5),
    },
    [`&:not(.${toggleButtonClasses.disabled})`]: {
        // colors taken from oulined primary button
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        [`&.${toggleButtonClasses.selected}`]: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.primary.dark,
            },
        },
    },
    "&:hover": {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
            backgroundColor: "transparent",
        },
    },
}));

export default ToggleButton;
