import {
    buildEffectKey,
    CalculationEffectListDto,
    EffectCategoryDto,
    EffectFilterCurrencyField,
    EffectType,
    EffectUpdateDto,
    EffectValueType,
    GlobalCalculationIdentifier,
} from "api-shared";
import { keyBy } from "lodash";
import { useCallback, useMemo } from "react";

interface IUseSignedEffectsProps {
    effectCategories: EffectCategoryDto[];
    calculationEffects: CalculationEffectListDto;
    updateEffects: (changes: EffectUpdateDto) => void;
}

const useSignedEffects = ({ calculationEffects, effectCategories, updateEffects }: IUseSignedEffectsProps) => {
    const ecById = useMemo(() => keyBy(effectCategories, (e) => e.id), [effectCategories]);

    const signedCalculationEffects = useMemo(
        () =>
            calculationEffects.map((calculationEffect) => {
                const effectType = ecById[calculationEffect.effectCategoryId]?.effectType;

                if (effectType !== EffectType.ChangeoverCosts) {
                    return calculationEffect;
                }

                const effect = { ...calculationEffect };

                Object.values(GlobalCalculationIdentifier).forEach((calculationIdentifier) => {
                    const effectKey = buildEffectKey(calculationIdentifier, EffectFilterCurrencyField.Effect);
                    effect[effectKey] = calculationEffect[effectKey] != null ? -calculationEffect[effectKey] : null;
                    const inputEffectKey = buildEffectKey(calculationIdentifier, EffectFilterCurrencyField.Effect, EffectValueType.Input);
                    effect[inputEffectKey] = calculationEffect[inputEffectKey] != null ? -calculationEffect[inputEffectKey] : null;
                });

                return effect;
            }),
        [ecById, calculationEffects],
    );

    const updateSignedEffects = useCallback(
        (changes: EffectUpdateDto) => {
            const { value, type, effectCategoryId } = changes;
            const effectType = ecById[effectCategoryId]?.effectType;

            const signedChanges = { ...changes };
            if (type === EffectFilterCurrencyField.Effect && effectType === EffectType.ChangeoverCosts && value != null) {
                signedChanges.value = -value;
            }

            updateEffects(signedChanges);
        },
        [ecById, updateEffects],
    );

    return [signedCalculationEffects, updateSignedEffects] as const;
};

export default useSignedEffects;
