import { Alert, Button, Collapse, DialogActions, Divider, FormControlLabel, FormGroup, Switch, styled } from "@mui/material";
import { EffectField, FeatureFlags } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import OverlineLabel from "../../../components/OverlineLabel";
import { useClientHasFeature } from "../../../domain/client";
import { translationKeys } from "../../../translations/main-translations";

const Root = styled("div")({
    width: 360,
    maxWidth: "100%",
});

const InlineAlert = styled(Alert)({
    borderRadius: 0,
});

interface LevelPopoverProps {
    onSave: (newInitial: boolean, newPriceHike: boolean) => void;
    defaultInitial: boolean;
    defaultPriceHike: boolean;
    translate: TFunction;
}

const LevelPopover = ({ translate, defaultInitial, defaultPriceHike, onSave: onSaveProps }: LevelPopoverProps) => {
    const [hasInitial, setHasInitial] = useState(defaultInitial);
    const [hasPriceHike, setHasPriceHike] = useState(defaultPriceHike);

    const hasExpectedPriceIncreaseFeature = useClientHasFeature(FeatureFlags.FEATURE_EXPECTED_PRICE_INCREASE);

    const hasRemovedType = (!hasInitial && defaultInitial) || (!hasPriceHike && defaultPriceHike);
    const hasChange = hasInitial !== defaultInitial || hasPriceHike !== defaultPriceHike;

    const onSave = () => onSaveProps(hasInitial, hasPriceHike);

    return (
        <Root>
            <FormGroup sx={{ py: 1, px: 2 }}>
                <OverlineLabel size="small" sx={{ mb: 0.5 }}>
                    {translate(translationKeys.VDLANG_LEVEL_POPOVER_HEADING)}
                </OverlineLabel>
                <FormControlLabel
                    control={
                        <Switch
                            checked={hasInitial}
                            onChange={(event) => {
                                setHasInitial(event.target.checked);
                                setHasPriceHike(false);
                            }}
                            name="hasInitial"
                        />
                    }
                    label={translate(EffectField.Initial)}
                />
                {hasExpectedPriceIncreaseFeature ? (
                    <FormControlLabel
                        disabled={!hasInitial}
                        control={
                            <Switch
                                checked={hasPriceHike}
                                onChange={(event) => setHasPriceHike(event.target.checked)}
                                name="hasPriceHike"
                            />
                        }
                        label={translate(EffectField.PriceHike)}
                    />
                ) : null}
            </FormGroup>
            <Collapse in={hasRemovedType} mountOnEnter unmountOnExit>
                <InlineAlert severity="warning">{translate(translationKeys.VDLANG_LEVEL_POPOVER_WARNING)}</InlineAlert>
            </Collapse>
            <Divider />
            <DialogActions sx={{ py: 1, px: 2 }}>
                <Button size="small" disabled={!hasChange} color="primary" variant="text" onClick={onSave}>
                    {translate(translationKeys.VDLANG_APPLY)}
                </Button>
            </DialogActions>
        </Root>
    );
};

export default LevelPopover;
