import { Fade, styled, Tooltip } from "@mui/material";
import React, { useCallback, useRef } from "react";
import CalculationEffectCategoryMenu from "./CalculationEffectCategoryMenu";
import { useCellContext } from "./CalculationTableCellContext";

const Root = styled("div")(({ theme }) => ({
    // container should span full cell, to ensure rightReference is always positioned at the end of the cell
    width: "100%",
    display: "flex",
    padding: theme.spacing(0.75, 1),
}));

const TopReference = styled("div")({
    // this serves as reference for the top of the whole table
    position: "absolute",
    top: 0,
});

const Caption = styled("div")({
    flexGrow: 1,
    overflowWrap: "anywhere", // also arbitrary breaking of words longer than line length
});

const TooltipRoot = styled("div")(({ theme }) => ({
    boxShadow: theme.shadows[1],
    marginTop: theme.spacing(0.25),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper, // make tooltip opaque
}));

interface IEffectCategoryHeaderCellProps {
    data: any; // provided by dxDataGrid
}

const EffectCategoryHeaderCell = ({ data }: IEffectCategoryHeaderCellProps) => {
    const { translate, onEditCategory, onRemoveCategory, onOpenHistory } = useCellContext();

    // element that is located at the horizontal end of the cell/column
    const rightReference = useRef<HTMLDivElement>(null);

    // element located at the top of the whole table
    const topReference = useRef<HTMLDivElement>(null);

    // This holds the anchor for tooltip positioning. It is constructed from the positions of the two reference elements
    // Goal: Show menu at the end of the first EffectCategoryHeaderCell of the column
    const computeTooltipAnchor = useCallback(() => {
        if (topReference.current == null || rightReference.current == null) {
            return null;
        }
        const rightRect = rightReference.current.getBoundingClientRect();
        const topRect = topReference.current.getBoundingClientRect();
        const x = rightRect.x + rightRect.width; // right end of rightReference element
        const y = topRect.y; // top of the table
        const refRect = new DOMRect(x, y, 0, rightRect.height);
        return {
            clientWidth: refRect.width,
            clientHeight: refRect.height,
            getBoundingClientRect: () => refRect,
        };
    }, [topReference, rightReference]);

    return (
        <Tooltip
            enterDelay={0}
            PopperProps={{
                // pass a function here, so anchor element will be computed just before the tooltip gets visible
                // This avoids positioning issues with scrolling or changing positions of reference elements
                anchorEl: computeTooltipAnchor as any, // Typings do not include functions that may return null
            }}
            components={{ Tooltip: TooltipRoot }}
            TransitionComponent={Fade} // slightly less intrusive when moving mouse between cells
            placement="left-start" // place content left of the artifical anchor, that is located at the top right of the first EffectCategoryHeaderCell
            title={
                <CalculationEffectCategoryMenu
                    onEdit={onEditCategory}
                    onRemove={onRemoveCategory}
                    onHistory={onOpenHistory}
                    effectCategoryId={data.column.effectCategoryId}
                    translate={translate}
                    allowEditing={data.column.allowEditing !== undefined && data.column.allowEditing}
                />
            }
        >
            <Root>
                <Caption>{data.column.caption}</Caption>
                <div ref={rightReference} />
                <TopReference ref={topReference} />
            </Root>
        </Tooltip>
    );
};

export default React.memo(EffectCategoryHeaderCell);
