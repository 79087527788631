import { useEffect, useState } from "react";
import { reportError } from "../infrastructure/sentry";

/**
 * This hook can be used for easier handling of dynamic imports while keeping full type safety.
 *
 * Usage:
 *
 * ```
 * const largeModule = useLazyModule(() => import("some-large-module"));
 * if (largeModule === undefined) {
 *   // handle loading state
 * }
 * // work with the module, e.g.
 * const { ComponentA, ComponentB } = largeModule;
 * ```
 *
 * @export
 * @template T
 * @param {() => Promise<T>} promiseFactory Provide a function that returns the promise of the dynamic import
 * @returns
 */
export default function useLazyModule<T>(promiseFactory: () => Promise<T>) {
    const [lazyModule, setLazyModule] = useState<T | undefined>();
    useEffect(() => {
        promiseFactory()
            .then(setLazyModule)
            .catch((e) => reportError(new Error("Error while lazy importing module"), { extras: { thrown: JSON.stringify(e) } }));
        // Run only on mount, ignore any changes to promiseFactory afterwards
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return lazyModule;
}
