import { Dialog, DialogProps } from "@mui/material";

const OptionalDialog = ({ open, children, ...dialogProps }: DialogProps) => {
    // functional components have sometimes issues with typescript, so cast children to any
    return open ? (
        <Dialog open={open} {...dialogProps}>
            {children}
        </Dialog>
    ) : (
        (children as any)
    );
};

export default OptionalDialog;
