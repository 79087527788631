import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Grid, styled, Tooltip, TooltipProps } from "@mui/material";
import type dxDataGrid from "devextreme/ui/data_grid";
import React from "react";
import useCellContext from "./CalculationTableCellContext";

enum Placement {
    Top, // show above the current cell
    Bottom, // show below the current cell
    Both, // show two buttons, one above and one below the cell (useful when there is only a single row)
}

const TooltipRoot = styled("div")({
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
});

const Root = styled("div")(({ theme }) => ({
    padding: theme.spacing(0.75, 1),
}));

const Container = styled(Grid)(({ theme }) => ({
    // make tooltip large than just button, so its easier to move the mouse onto the button
    minHeight: theme.spacing(4), // row height
}));

const AddButton = styled(Button, { shouldForwardProp: (name) => name !== "placement" && name !== "hasBothButtons" })<{
    placement: Placement;
    hasBothButtons: boolean;
}>(({ theme, placement, hasBothButtons }) => {
    const verticalOffset = theme.spacing(hasBothButtons ? 0.75 : 1.25);
    return {
        padding: 0,
        minWidth: 0,
        borderRadius: theme.shape.borderRadius,
        visibility: "visible",
        ...(placement === Placement.Top && { transform: `translateY(-${verticalOffset})` }),
        ...(placement === Placement.Bottom && { transform: `translateY(${verticalOffset})` }),
    };
});

interface ICalculationTableLabelCellProps {
    data: any;
    placement?: Placement;
}

const CalculationTableLabelCell = ({ placement: placementProps, data }: ICalculationTableLabelCellProps) => {
    const { onAddTimeslice } = useCellContext();
    const component = data.component as dxDataGrid;
    const rows = component.getVisibleRows();

    // rows are not always referentially equal, so compare by key here
    const isFirstRow = data.row != null && rows[0]?.key === data.row.key;
    const isLastRow = data.row != null && rows[rows.length - 1]?.key === data.row.key;

    let placement: Placement | undefined;
    if (placementProps === undefined) {
        if (isFirstRow && isLastRow) {
            placement = Placement.Both;
        } else if (isFirstRow) {
            placement = Placement.Top;
        } else if (isLastRow) {
            placement = Placement.Bottom;
        }
    } else {
        placement = placementProps;
    }

    const child = <Root>{data.text}</Root>;
    if (placement === undefined) {
        // no enforced placement and not a first/last row
        return child;
    }
    const PLACEMENTS = {
        [Placement.Both]: "left",
        [Placement.Top]: "left-start",
        [Placement.Bottom]: "left-end",
    } as Record<Placement, TooltipProps["placement"]>;

    return (
        <Tooltip
            placement={PLACEMENTS[placement]}
            components={{ Tooltip: TooltipRoot }}
            title={
                <Container container direction="column" justifyContent="space-between">
                    {placement === Placement.Top || placement === Placement.Both ? (
                        <AddButton
                            placement={Placement.Top}
                            hasBothButtons={placement === Placement.Both}
                            variant="contained"
                            onClick={() => onAddTimeslice("top")}
                        >
                            <AddIcon fontSize="small" />
                        </AddButton>
                    ) : (
                        <div />
                    )}
                    {placement === Placement.Bottom || placement === Placement.Both ? (
                        <AddButton
                            placement={Placement.Bottom}
                            hasBothButtons={placement === Placement.Both}
                            variant="contained"
                            onClick={() => onAddTimeslice("bottom")}
                        >
                            <AddIcon fontSize="small" />
                        </AddButton>
                    ) : (
                        <div />
                    )}
                </Container>
            }
            // Disable transition, which causes flickering when moving mouse during transition
            TransitionProps={{ timeout: 0 }}
        >
            {child}
        </Tooltip>
    );
};

export default React.memo(CalculationTableLabelCell);
