import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import { Divider, styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { TFunction } from "i18next";
import Tooltip from "../../../components/Tooltip";

const FlexToggleButtonGroup = styled(ToggleButtonGroup)({
    // default is inline-flex, which adds additional space below
    display: "flex",
    fontSize: "1rem",
});

const BorderlessToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: "none",
    padding: theme.spacing(0.5, 1),
}));

const SpacedDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(0.5, 0),
    backgroundColor: "transparent",
}));

interface ICalculationEffectCategoryMenuProps {
    effectCategoryId: number;
    translate: TFunction;
    onEdit: (categoryId: number) => void;
    onHistory: (categoryId: number) => void;
    onRemove: (categoryId: number) => void;
    allowEditing: boolean;
}

const CalculationEffectCategoryMenu = ({
    effectCategoryId,
    translate,
    onEdit,
    onHistory,
    onRemove,
    allowEditing,
}: ICalculationEffectCategoryMenuProps) => {
    return (
        <FlexToggleButtonGroup color="standard">
            {allowEditing ? (
                <>
                    <BorderlessToggleButton value="edit" onClick={() => onEdit(effectCategoryId)}>
                        <Tooltip title={translate("Edit")}>
                            <EditRoundedIcon fontSize="inherit" />
                        </Tooltip>
                    </BorderlessToggleButton>
                    <SpacedDivider flexItem orientation="vertical" />
                </>
            ) : null}
            <BorderlessToggleButton value="history" onClick={() => onHistory(effectCategoryId)}>
                <Tooltip title={translate("show_history")}>
                    <HistoryRoundedIcon fontSize="inherit" />
                </Tooltip>
            </BorderlessToggleButton>
            {allowEditing ? (
                <>
                    <SpacedDivider flexItem orientation="vertical" />
                    <BorderlessToggleButton value="remove" onClick={() => onRemove(effectCategoryId)}>
                        <Tooltip title={translate("delete")}>
                            <DeleteRoundedIcon fontSize="inherit" />
                        </Tooltip>
                    </BorderlessToggleButton>
                </>
            ) : null}
        </FlexToggleButtonGroup>
    );
};

export default CalculationEffectCategoryMenu;
