import { Stack } from "@mui/material";
import { TFunction } from "i18next";
import Tooltip from "../../../components/Tooltip";
import { translationKeys } from "../../../translations/main-translations";
import CalculationIdentifierChip from "../levels/CalculationIdentifierChip";

interface LevelSelectorProps {
    visibleCalculationIdentifiers: string[];
    updateVisibleCalculationIdentifiers: (newLevels: string[]) => void;
    calculationIdentifiers: string[];
    translate: TFunction;
    disabled?: boolean;
}

const LevelSelector = ({
    visibleCalculationIdentifiers,
    calculationIdentifiers,
    updateVisibleCalculationIdentifiers,
    translate,
    disabled = false,
}: LevelSelectorProps) => {
    const makeUpdateCalculationIdentifier = (calculationIdentifier: string) => () => {
        const newLevels = visibleCalculationIdentifiers.includes(calculationIdentifier)
            ? visibleCalculationIdentifiers.filter((l) => l !== calculationIdentifier)
            : [...visibleCalculationIdentifiers, calculationIdentifier];
        updateVisibleCalculationIdentifiers(newLevels);
    };

    const tooltips: any = calculationIdentifiers.reduce((all, name) => {
        const disabledOrActiveState = visibleCalculationIdentifiers.length === 1 ? "disabled" : "active";
        const chipState = !visibleCalculationIdentifiers.includes(name) ? "inactive" : disabledOrActiveState;
        return {
            ...all,
            [name]: translate(`${translationKeys.VDLANG_CALCULATION_TABLE_TOOLTIP}.chip_state_${chipState}`, {
                calculationIdentifier: translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${name}`),
            }),
        };
    }, {});

    return (
        <Stack direction="row" p={1} spacing={0.5}>
            {calculationIdentifiers.map((name) => (
                <Tooltip key={name} title={tooltips[name]}>
                    <CalculationIdentifierChip
                        disabled={disabled || (visibleCalculationIdentifiers.length === 1 && visibleCalculationIdentifiers[0] === name)}
                        label={translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${name}`)}
                        variant={visibleCalculationIdentifiers.includes(name) ? "active" : undefined}
                        onClick={makeUpdateCalculationIdentifier(name)}
                    />
                </Tooltip>
            ))}
        </Stack>
    );
};

export default LevelSelector;
