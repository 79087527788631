import FileCopyIcon from "@mui/icons-material/FileCopyRounded";
import MoreVertIcon from "@mui/icons-material/MoreVertRounded";
import { IconButton, Popover, Stack, Typography, styled } from "@mui/material";
import { EffectFilterCurrencyField } from "api-shared";
import React from "react";
import Tooltip from "../../../components/Tooltip";
import useMenu from "../../../hooks/useMenu";
import { translationKeys } from "../../../translations/main-translations";
import useCellContext from "./CalculationTableCellContext";
import LevelPopover from "./LevelPopover";

const buttonContainerClass = "VdLevelHeaderCell-buttonContainer";

const Root = styled("div", { shouldForwardProp: (name) => name !== "menuOpen" })<{ menuOpen: boolean }>(({ theme, menuOpen }) => ({
    position: "relative",
    padding: theme.spacing(0.75, 1),
    // hover state is lost when menu is opened so keep buttons visible statically
    ...(!menuOpen && {
        [`&:not(:hover) > .${buttonContainerClass}`]: {
            display: "none",
        },
    }),
}));

const ButtonContainer = styled(Stack)(({ theme }) => ({
    // hidden button container by default, will only get visible on cell hover or in open state
    position: "absolute",
    right: theme.spacing(0.5),
    top: 0,
    height: "100%",
    width: theme.spacing(8), // fixed width so gradient works as intended
    background: `linear-gradient(270deg, ${theme.palette.background.paper} 68.18%, transparent 100%)`,
}));

const SmallerIconButton = styled(IconButton)({
    // icon inside should be smaller than fontSize="small"
    fontSize: "1rem",
});

interface LevelHeaderCellProps {
    data: any; // provided by dxDataGrid
}

const LevelHeaderCell = ({ data }: LevelHeaderCellProps) => {
    const { openMenu, closeMenu, menuProps } = useMenu();

    const { effectCategoryId, calculationIdentifier, isNonLinear, caption, copyEnabled } = data.column;
    const { translate, onCopyLevel, onSaveColumns, getIsFieldVisible } = useCellContext();

    const hasInitial = getIsFieldVisible(effectCategoryId, calculationIdentifier, EffectFilterCurrencyField.Initial);
    const hasPriceHike = getIsFieldVisible(effectCategoryId, calculationIdentifier, EffectFilterCurrencyField.PriceHike);

    let nonLinearText = null;
    if (isNonLinear) {
        nonLinearText = (
            <Typography
                component="span"
                color="textSecondary"
            >{` • ${translate(translationKeys.VDLANG_LEVEL_HEADER_NONLINEAR_HINT)}`}</Typography>
        );
    }

    return (
        <Root menuOpen={menuProps.open}>
            <Popover {...menuProps}>
                <LevelPopover
                    translate={translate}
                    defaultInitial={hasInitial}
                    defaultPriceHike={hasPriceHike}
                    onSave={(newHasInitial, newHasPriceHike) => {
                        onSaveColumns(effectCategoryId, calculationIdentifier, newHasInitial, newHasPriceHike);
                        closeMenu();
                    }}
                />
            </Popover>
            {caption} {nonLinearText}
            <ButtonContainer direction="row-reverse" alignItems="center" spacing={0.5} className={buttonContainerClass}>
                <Tooltip title={translate(`${translationKeys.VDLANG_CALCULATION_TABLE_TOOLTIP}.add_or_remove_effect_column`)}>
                    <SmallerIconButton size="small" onClick={openMenu}>
                        <MoreVertIcon fontSize="inherit" />
                    </SmallerIconButton>
                </Tooltip>
                {copyEnabled && (
                    <Tooltip
                        title={translate(`${translationKeys.VDLANG_CALCULATION_TABLE_TOOLTIP}.copy_effect_column`, {
                            prevCalculationIdentifier: data.column.prevCaption,
                        })}
                    >
                        <SmallerIconButton size="small" onClick={() => onCopyLevel(effectCategoryId, calculationIdentifier)}>
                            <FileCopyIcon fontSize="inherit" />
                        </SmallerIconButton>
                    </Tooltip>
                )}
            </ButtonContainer>
        </Root>
    );
};

export default React.memo(LevelHeaderCell);
